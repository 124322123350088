import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { PineJS } from '~/modules/SDK/Chart2/PineJS'

/**
 * Keltner Channel
 * @param this
 * @param length 期數
 * @param atrs ATR倍數
 * @returns PineJS.NewVar<number>[上通道, 下通道線, SMA]
 */
export function keltnerChannel(
  this: ChartTypes.IndicatorThis,
  length: number,
  atrs: number,
): number[] {
  const close: PineJS.NewVar<number> = this.ohlc.closeArray

  const sma: number = this.average(close, length)
  const keltnerHigh: number = sma + atrs * this.atr(length)
  const keltnerLow: number = sma - atrs * this.atr(length)
  return [keltnerHigh, keltnerLow, sma]
}
