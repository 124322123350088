import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { PineJS } from '~/modules/SDK/Chart2/PineJS'

/**
 * MACD
 * @param this
 * @param fastLength 快速線（短期）的期數
 * @param slowLength 慢速線（長期）的期數
 * @param macdLength MACD使用之平滑期數
 * @returns number[dif, macd, osc]
 */
export function macd(
  this: ChartTypes.IndicatorThis,
  fastLength: number,
  slowLength: number,
  macdLength: number,
): number[] {
  const price: PineJS.NewVar<number> = this._context.new_var(this.weightedClose())
  price.get(this.maxList([fastLength, slowLength, macdLength]) + 2)
  const fastMA = this.exponentialAverage(price, fastLength)
  const slowMA = this.exponentialAverage(price, slowLength)

  // Dif
  const difValue = fastMA - slowMA
  const dif: PineJS.NewVar<number> = this._context.new_var(difValue)
  dif.get(this.maxList([fastLength, slowLength, macdLength]) + 2)

  // MACD
  const macdValue = this.exponentialAverage(dif, macdLength)

  // OSC
  const oscValue = difValue - macdValue
  return [difValue, macdValue, oscValue]
}
