import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { PineJS } from '~/modules/SDK/Chart2/PineJS'
import { SessionType } from '~/modules/SDK/Chart2/SessionType'

/**
 * 計算當日CDP價位
 * @param this
 * @param sessionType 交易時段
 * @returns PineJS.NewVar<number>[AH, NH, CDP, NL, AL]
 */
export function cdp(this: ChartTypes.IndicatorThis, sessionType: SessionType): number[] {
  const prevHigh: number = this.dailyHigh(3, sessionType)[1]
  const prevLow: number = this.dailyLow(3, sessionType)[1]
  const prevClose: number = this.dailyClose(3, sessionType)[1]

  const cdpValue: number = (prevHigh + prevLow + 2 * prevClose) * 0.25
  const ahValue = cdpValue + (prevHigh - prevLow)
  const nhValue = 2 * cdpValue - prevLow
  const nlValue = 2 * cdpValue - prevHigh
  const alValue = cdpValue - (prevHigh - prevLow)

  return [ahValue, nhValue, cdpValue, nlValue, alValue]
}
