import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { PineJS } from '~/modules/SDK/Chart2/PineJS'
import { SessionType } from '~/modules/SDK/Chart2/SessionType'

/**
 *
 * @param this
 * @param length 計算天數
 * @param fraction 通道大小乘數
 * @param sessionType 交易時段
 * @returns PineJS.NewVar<number>[GS-High, GS-Low]
 */
export function greatestSwingChannel(
  this: ChartTypes.IndicatorThis,
  length: number,
  fraction: number,
  sessionType: SessionType,
): number[] {
  const dailyOpen: number[] = this.dailyOpen(length, sessionType)

  const greatestSwing: number[] = this.greatestSwing(length, sessionType)
  const longGreatestSwing: number = greatestSwing[0]
  const shortGreatestSwing: number = greatestSwing[1]

  return [dailyOpen[0] + fraction * longGreatestSwing, dailyOpen[0] - fraction * shortGreatestSwing]
}
