import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { PineJS } from '~/modules/SDK/Chart2/PineJS'
import { SessionType } from '~/modules/SDK/Chart2/SessionType'

/**
 * 計算三關價
 * @param this
 * @param sessionType 交易時段
 * @returns number[上關價, 中關價, 下關價]
 */
export function threeGatePrice(this: ChartTypes.IndicatorThis, sessionType: SessionType): number[] {
  const prevHigh: number = this.dailyHigh(5, sessionType)[1]
  const prevLow: number = this.dailyLow(5, sessionType)[1]

  const highGateValue = prevLow + (prevHigh - prevLow) * 1.382
  const midGateValue = (prevHigh + prevLow) * 0.5
  const lowGateValue = prevHigh - (prevHigh - prevLow) * 1.382
  return [highGateValue, midGateValue, lowGateValue]
}
