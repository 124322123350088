import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { PineJS } from '~/modules/SDK/Chart2/PineJS'
import { SessionType } from '~/modules/SDK/Chart2/SessionType'

/**
 * Dual Thrust通道指標
 * Reference: https://zhuanlan.zhihu.com/p/41989276
 * @param this
 * @param length 天數
 * @param fraction 通道大小乘數
 * @param sessionType 交易時段
 * @returns PineJS.NewVar<number>[上通道, 下通道]
 */
export function dualThrust(
  this: ChartTypes.IndicatorThis,
  length: number,
  fraction: number,
  sessionType: SessionType,
): number[] {
  const dailyOpen: number[] = this.dailyOpen(length + 2, sessionType)

  const dualThrustRange: PineJS.NewVar<number> = this._context.new_var()
  dualThrustRange.set(this.dualThrustRange(length, sessionType))
  dualThrustRange.get(512)

  return [
    dailyOpen[0] + fraction * dualThrustRange.get(0),
    dailyOpen[0] - fraction * dualThrustRange.get(0),
  ]
}
