import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { PineJS } from '~/modules/SDK/Chart2/PineJS'

/**
 * BBand
 * @param this
 * @param series 數列
 * @param length 期數
 * @param stds 標準差倍數
 * @returns number[上通道, 下通道, SMA]
 */
export function bollingerBand(
  this: ChartTypes.IndicatorThis,
  series: PineJS.NewVar<number>,
  length: number,
  stds: number,
): number[] {
  const sma: number = this.average(series, length)
  const standardDev: number = this.standardDev(series, length)

  const bbandHigh: number = sma + stds * standardDev
  const bbandLow: number = sma - stds * standardDev

  return [bbandHigh, bbandLow, sma]
}
