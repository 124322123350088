import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { PineJS } from '~/modules/SDK/Chart2/PineJS'
import { SessionType } from '~/modules/SDK/Chart2/SessionType'

/**
 * Pivot Point
 * @param this
 * @param sessionType 交易時段
 * @returns PineJS.NewVar<number>[壓力3, 壓力2, 壓力1, PivotPoint, 支撐1, 支撐2, 支撐3]
 */
export function pivotPoint(this: ChartTypes.IndicatorThis, sessionType: SessionType): number[] {
  const prevHigh: number = this.dailyHigh(5, sessionType)[1]
  const prevLow: number = this.dailyLow(5, sessionType)[1]
  const prevClose: number = this.dailyClose(5, sessionType)[1]

  const pivotPointValue: number = (prevHigh + prevLow + prevClose) / 3
  const r3: number = prevHigh + 2 * (pivotPointValue - prevLow)
  const r2: number = pivotPointValue + prevHigh - prevLow
  const r1: number = 2 * pivotPointValue - prevLow
  const s1: number = 2 * pivotPointValue - prevHigh
  const s2: number = pivotPointValue - prevHigh + prevLow
  const s3: number = prevLow - 2 * (prevHigh - pivotPointValue)

  return [r3, r2, r1, pivotPointValue, s1, s2, s3]
}
