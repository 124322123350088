import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { SessionType } from '~/modules/SDK/Chart2/SessionType'

/**
 * 日內的強弱關價
 *
 * @param this
 * @param sessionType 交易時段
 * @returns Number[strongGate, weakGate]
 */
export function strongWeakGate(this: ChartTypes.IndicatorThis, sessionType: SessionType): number[] {
  const dayHigh: number = this.intradayHighestV2(this.ohlc.highArray, this.timeArray, sessionType)
  const dayLow: number = this.intradayLowestV2(this.ohlc.lowArray, this.timeArray, sessionType)
  const strongPrice: number = dayLow + (dayHigh - dayLow) * 0.618
  const weakPrice: number = dayLow + (dayHigh - dayLow) * 0.382
  return [strongPrice, weakPrice]
}
