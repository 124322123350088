import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { PineJS } from '~/modules/SDK/Chart2/PineJS'

/**
 * @param this
 * @param length: number 期數
 * @returns number[上通道, 下通道]
 */
export function donchianChannel(this: ChartTypes.IndicatorThis, length: number): number[] {
  const high = this.ohlc.highArray
  const low = this.ohlc.lowArray

  const donchianHigh: number = this.highest(high, length)
  const donchianLow: number = this.lowest(low, length)

  return [donchianHigh, donchianLow]
}
