import { ChartTypes } from '~/modules/SDK/Chart2/ChartTypes'
import { PineJS } from '~/modules/SDK/Chart2/PineJS'

/**
 * KD
 * @param this
 * @param length 期數
 * @param rsvt K值平滑期數
 * @param kt D值平滑期數
 * @returns number[k, d, rsv]
 */
export function stochastic(
  this: ChartTypes.IndicatorThis,
  length: number,
  rsvt: number,
  kt: number,
): number[] {
  const close: PineJS.NewVar<number> = this.ohlc.closeArray
  const high: PineJS.NewVar<number> = this.ohlc.highArray
  const low: PineJS.NewVar<number> = this.ohlc.lowArray

  const maxHigh: number = this.highest(high, length)
  const minLow: number = this.lowest(low, length)
  const k: PineJS.NewVar<number> = this._context.new_var()
  const d: PineJS.NewVar<number> = this._context.new_var()
  k.get(length + 2)
  d.get(length + 2)
  // Calculate rsv
  let rsvValue = 0
  if (maxHigh !== minLow) {
    rsvValue = (100 * (close.get(0) - minLow)) / (maxHigh - minLow)
  } else {
    rsvValue = 50
  }

  // Calculate k / d
  if (isNaN(k.get(0))) {
    k.set(50)
    d.set(50)
  } else {
    const kValue = (k.get(1) * (rsvt - 1) + rsvValue) / rsvt
    const dValue = (d.get(1) * (kt - 1) + kValue) / kt
    k.set(kValue)
    d.set(dValue)
  }
  return [k.get(0), d.get(0), rsvValue]
}
